import { createContext, useContext, useEffect, useState } from "react";
import { auth, db } from '../services/firebase'
import {
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword, 
    signOut, 
    onAuthStateChanged, 
    GoogleAuthProvider,
    signInWithPopup,
    signInWithRedirect,
} from 'firebase/auth';
import {setDoc, doc, getDoc} from 'firebase/firestore'


const AuthContext = createContext()

export function AuthContextProvider({children}) {
    const [user, setUser] = useState({});
    

    {/******** Google sign in ********/}
    const googleSignIn = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
       // signInWithRedirect(auth, provider)
    }

    {/********  Sign up with email ********/}
    // function signUp(email, password){
    //     createUserWithEmailAndPassword(auth, email, password);
    //     setDoc(doc(db, 'users', email), {
    //         savedShows: [],
    //     })
    // }

    async function signUp(email, password) {
    try {
        await createUserWithEmailAndPassword(auth, email, password);

        await setDoc(doc(db, 'users', email), {
            savedShows: [],
        });

        console.log('User created and Firestore document set successfully.');
    } catch (error) {
        console.error('Error creating user and setting Firestore document:', error);
    }
}


    {/********  Log in with email  ********/}
    function logIn(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
        }

    {/********  log out ********/}
    function logOut(){
        return signOut(auth);
    }


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser)
        })
        return () =>
        unsubscribe();
    })

    {/********  check database if user has an account ********/}
    async function userExists(email) {
        try {
            const userDoc = await getDoc(doc(db, 'users', email));
            return userDoc.exists();
        } catch (error) {
            console.error('Error checking user existence:', error);
            return false;
        }
    }

    return(
        <AuthContext.Provider value = {{ signUp, logIn, logOut, user, googleSignIn }}>
            {children}
        </AuthContext.Provider>
    )
}


export function UserAuth(){
    return useContext(AuthContext)
}