import React, { useEffect, useState } from 'react';
import GoogleButton from 'react-google-button';
import { IoCloseOutline } from "react-icons/io5";
import { UserAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { BiHide, BiShow } from 'react-icons/bi';


const AuthPopupForm = ({ closeModal }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignIn, setIsSignIn] = useState(true);
  const { googleSignIn, user, signUp, logIn  } = UserAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('')


  const handleToggleForm = async () => {
    setIsSignIn(!isSignIn);
  };


  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setError('');
  //   try {
  //     await logIn(email, password);
  //   } catch (error) {
  //     console.log(error);
  //     setError(error.message);
  //   }
  
  //   // Clear form fields after submission
  //   setEmail('');
  //   setPassword('');
  // };


const handleSubmit = async (e) => {
  e.preventDefault();
  setError('');

  try {
    if (isSignIn) {
      // Log in case
      await logIn(email, password);
    } else {
      // Sign-up case
      await signUp(email, password);
    }
    // Clear form fields after successful submission
    setEmail('');
    setPassword('');
  } catch (error) {
    console.log(error);
    setError(error.message);
    // Clear form fields after submission with an error
    setEmail('');
    setPassword('');
  }
};

  


{/********** Google sign in **********/}
  const navigate = useNavigate()

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };
  

  useEffect(() => {
    if (user !== null && !isSignIn) {
      // Check if the user is not null and the form is not in the sign-in mode
      navigate('/browse');
    }
  }, [user, isSignIn, navigate]);
  

  return (
    <div className = 'relative bg-white shadow-lg rounded-md'>
    <div className="p-16">

    {/********** close button **********/}
      <div>
      <button
      className='absolute top-2 right-2 z-2 p-2'>
      <IoCloseOutline
      onClick = {closeModal}
      className = 'text-2xl text-stone-500 hover:text-stone-300' />
      </button>
      </div>

      <div className = 'mb-8 flex justify-center'>
        <h1 className = 'text-purple font-bold text-2xl'>
           {isSignIn ? 'Log in to Legatto' : 'Sign up for Legatto'}
        </h1>

        {error && (
  <div className='mb-4 flex justify-center'>
    <p className='text-red'>{error}</p>
  </div>
)}
      </div>



    {/********** Google sign in button **********/}
      <div className = 'flex justify-center'>
        <GoogleButton
        label = {isSignIn ? 'Log in with Google' : 'Sign in with Google'}
        onClick = {handleGoogleSignIn} />
        </div>     


        <div className="flex items-center justify-center my-4">
          <div className="border-b border-stone-400 flex-1"></div>
          <span className="mx-4 text-gray-500">or</span>
          <div className="border-b border-stone-400 flex-1"></div>
        </div>   


    {/********** Email + password form **********/}
      <div>
        <form 
        className = 'w-full flex flex-col'
        onSubmit={handleSubmit}>
          <input
            className = 'p-3 my-2  border-2 border-stone-300 rounded focus:outline-none focus:border-1 focus:border-orange'
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder = 'Email'
            autoComplete = 'email'
            required
          />
          <div className='relative'>
              <input
                className='w-full p-3 my-2 border-2 border-stone-300 rounded focus:outline-none focus:border-1 focus:border-orange pr-10'
                type={showPassword ? 'text' : 'password'} // Toggle between text and password
                onChange={(e) => setPassword(e.target.value)}
                placeholder={isSignIn ? 'Password' : 'Create password'}
                autoComplete='current-password'
                required
              />
              <span
                className='absolute top-0 right-0 bottom-0 pr-4 flex items-center cursor-pointer'
                onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <BiShow /> : <BiHide />}
              </span>
            </div>
          <button
        className='p-3 my-2 bg-orange hover:bg-hov-orange transition duration-100 ease-in-out w-full text-purple'>
          {isSignIn ? 'Log in with email' : 'Create account'}
          </button>
          </form>
          {isSignIn && (
            <p
              link='/'
              className='flex justify-center py-4 cursor-pointer text-blue-500 hover:text-black'>
              Forgot your password?
            </p>
          )}

    </div>
    </div>

    {/********* Toggle between sign up & login form ***********/}
      <div className="flex p-4 w-full bg-gray-100 justify-center">
        <button onClick={handleToggleForm}>
        <span className="text-blue-500 hover:text-blue-600">
          {isSignIn ? "Don't have an account? Sign Up" : 'Already have an account? Sign In'}
         </span>
       </button>
     </div>
    </div>
  );
};

export default AuthPopupForm;
