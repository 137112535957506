import { SearchBox } from 'react-instantsearch';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';

const AlgoliaSearchbar = () => {

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  }

  return (

<div className="relative">
<button
type="button"
className="text-purple absolute inset-y-0 left-7 mb-5 font-extrabold text-2xl z-10" 
onClick={goBack}
  >
<AiOutlineArrowLeft />
</button>
<SearchBox
  placeholder="Search by composer, piece, difficulty, or more!"
  classNames={{
 root: "mt-2 mb-[-8px] p-1 ml-1",
 form: "relative",
  input:
    "block w-full p-3 pl-12 text-md border border-purple text-purple placeholder-purple",
  submitIcon: "absolute inset-y-0 right-10 my-5 w-4 h-4 fill-purple",
}}
/>
</div>
  )
}

export default AlgoliaSearchbar;

