import React from 'react';
import { useNavigate } from 'react-router-dom';
import getIconByInstrumentType from '../../../metadata/iconIdentifier';


const getRandomCoverArt = () => {
  const randomNumber = Math.floor(Math.random() * 15) + 1; 
  return `../assets/scoreart/Placeholder-${randomNumber}.png`;
};


const Carousel = ({ data }) => {
  const navigate = useNavigate(); 

  const handleResultClick = (objectID) => {
    navigate(`/scores/${objectID}`);
  };

  return (
    <div>
      {data.map((item, index) => (
        <div 
          key={index} 
          onClick={() => handleResultClick(item.objectID)}
          className='w-[180px] sm:w-[230px] inline-block cursor-pointer mr-4 hover:bg-hov-off-white transition duration-100 p-2 rounded-lg active:bg-click-off-white'>
          <img className='w-full h-auto mb-2' 
          src={item.cover_art || getRandomCoverArt()} 
          alt='cover art' 
          loading="lazy" />
          <div className='flex'>
            <p className='leading-7 text-base font-dico font-bold text-purple flex flex-wrap truncate'>{item.title}</p>
          </div>
          <div className='flex mb-1'>
            <p className='font-dico-code font-bold text-sm text-purple text-transform: uppercase'>{item.composer_name}</p>
          </div>
          <div className='flex'>
          {getIconByInstrumentType(item.instrument, 'w-[14px]', 'text-sm')}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Carousel;
