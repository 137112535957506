import algoliasearch from 'algoliasearch/lite';

const searchClient = algoliasearch('YEBKU3WQGV', '11b0caf54cf089a912d7f64f0e3e29fd');
const index = searchClient.initIndex('scores');

const applyFilterAndRedirect = (facetName, subcategory, navigate) => {
  const filter = `${facetName}:"${subcategory}"`;

  return index
    .search('', {
      filters: filter,
      hitsPerPage: 100
    })
    .then(({ hits }) => {
      navigate(`/browse/${encodeURIComponent(subcategory)}`, { state: { hits } });
    })
    .catch((err) => {
      console.error(err);
    });
};

export { index, applyFilterAndRedirect };




