import React from 'react';
import { NavLink } from 'react-router-dom';
import { BsMusicNote } from 'react-icons/bs';
import { AiOutlineSearch } from 'react-icons/ai';
import { MdOutlineLibraryMusic } from 'react-icons/md';

const MobileBottomNavbar = () => {

  return (
    <div className="px-4 bg-white h-[60px] w-screen flex items-center shadow-gray-900 shadow-2xl">
      <div className="w-full flex justify-between">

      {/*********  browse tab **********/}
        <button
          className={`flex items-center hover:bg-gray-100 active:bg-gray-300 ${window.location.pathname === '/browse' ? 'bg-background-orange' : ''}`}>
          <NavLink
            to="/browse"
            className = 'flex flex-col items-center'
          >
            <BsMusicNote className="text-orange text-xl mb-0.5" />
            <span className=' text-purple font-bold text-[10px]'>              
              Browse</span>
          </NavLink>
        </button>

        {/*********  search tab **********/}
        <button
          className={`flex items-center hover:bg-gray-100 active:bg-gray-300 ${window.location.pathname === '/search' ? 'bg-background-orange' : ''}`}>
          <NavLink to="/search"
          className = 'flex flex-col items-center'>
            <AiOutlineSearch className="text-orange mb-0.5 text-xl" />
            <span 
            className='text-purple font-bold text-[10px]'> 
              Search</span>
          </NavLink>
        </button>

        {/*********  library tab **********/}
        <button
        className={`flex items-center hover:bg-gray-100 active:bg-gray-300 ${window.location.pathname === '/library' ? 'bg-background-orange' : ''}`}>
          <NavLink to="/library"
          className = 'flex flex-col items-center'>
            <MdOutlineLibraryMusic className="text-orange mb-0.5 text-xl" />
            <span className='text-purple font-bold text-[10px]'> 
              Library</span>
          </NavLink>
        </button>
      </div>
    </div>
  );
};

export default MobileBottomNavbar;
