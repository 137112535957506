import React, { useState, useEffect } from 'react';
import CTAButton from '../../Common/Buttons/CTAButton';

const Hero = () => {
  const variations = [
    'piano obsession',
    'violin inspiration',
    'guitar passion',
    'music journey',
  ];

  const [currentVariationIndex, setCurrentVariationIndex] = useState(0);
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setAnimate(false); // Disable animation
      setTimeout(() => {
        setCurrentVariationIndex((prevIndex) => (prevIndex + 1) % variations.length);
        setAnimate(true); // Enable animation after word change
      }, 50); // A small delay to allow the CSS class to be removed before reapplying
    }, 5000);

    // Clear the interval on component unmount to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [variations.length]);

  return (
    <div className='flex flex-col lg:flex-row lg:gap-x-14 items-center justify-between'>
      
      {/*********** Left Container for main heading  ****************/}
      <div className='flex flex-col w-full justify-center sm:p-24 md:px-28 md:py-8'>
        <h1 className='text-5xl text-[44px] font-black font-serif text-orange'>
        Find your next...
          <div
            className={`text-purple ${animate ? 'animate-in slide-in-from-top duration-700 fade-in-25' : ''}`}
          >
            {variations[currentVariationIndex]}
          </div>
        </h1>

        <div className='my-10'>
          <CTAButton text='Browse music now' link='/browse' />
        </div>
      </div>

      {/************* Right Container for Hero Image **************/}
      <div className='flex max-w-[460px]'>
        <img
          src='/assets/main/Hero_image.png'
          alt='hero'
          loading='lazy'
        />
      </div>
    </div>
  );
};

export default Hero;

