// aloglia filters ->
// auto searches to redirect users to category page

const period = [
    { facetName: 'period', subcategory: 'Baroque' },
    { facetName: 'period', subcategory: 'Classical' },
    { facetName: 'period', subcategory: 'Romantic' },
    { facetName: 'period', subcategory: 'Early Romantic' },
    { facetName: 'period', subcategory: 'Folk' },
    { facetName: 'period', subcategory: 'Late Romantic' },
    { facetName: 'period', subcategory: 'Renaissance' },
    { facetName: 'period', subcategory: 'Technique' },
    { facetName: 'period', subcategory: 'Modern' },
    { facetName: 'period', subcategory: 'Jazz' },
  ];

  const instrument = [
    { facetName: 'instrument', subcategory: 'Guitar' },
    { facetName: 'instrument', subcategory: 'Orchestra' },
    { facetName: 'instrument', subcategory: 'Violin' },
    { facetName: 'instrument', subcategory: 'Piano' },
    { facetName: 'instrument', subcategory: 'Cello' },
    { facetName: 'instrument', subcategory: 'Bass' },
    { facetName: 'instrument', subcategory: 'Horn' },
    { facetName: 'instrument', subcategory: 'Voice' },
  ];


  const composer = [
    { facetName: 'composer_name', subcategory: 'Wolfgang Amadeus Mozart' },
    { facetName: 'composer_name', subcategory: 'Johann Sebastian Bach' },
    { facetName: 'composer_name', subcategory: 'Ludwig van Beethoven' },
    { facetName: 'composer_name', subcategory: 'Franz Schubert' },
    { facetName: 'composer_name', subcategory: 'Frédéric Chopin' },
    { facetName: 'composer_name', subcategory: 'Felix Mendelssohn' },
  ];


  const workType = [
    { facetName: 'workType', subcategory: 'Solo' },
    { facetName: 'workType', subcategory: 'duet' },
    { facetName: 'workType', subcategory: 'Ensemble' },
    { facetName: 'workType', subcategory: 'technique' },
    { facetName: 'workType', subcategory: 'etude' },
    { facetName: 'workType', subcategory: 'quartet' },
    { facetName: 'workType', subcategory: 'orchestra' },
    { facetName: 'workType', subcategory: 'concerto' },
  ];
  


  export { period, instrument, composer, workType };
  