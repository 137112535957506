import React from 'react'
import { BiTrendingUp } from 'react-icons/bi';
import { List, ListItem, Card } from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Hits, useInstantSearch, Index, Configure } from 'react-instantsearch';
import Search from '../components/Hits/Search/Search';
import AlgoliaSearchbar from '../components/Common/Searchbar/AlgoliaSearchbar';
import MobileBottomNavbar from '../components/Common/Navbar/MobileBottomNavbar';
import Composers from '../components/Hits/Search/Composers';
import AutoSearch from '../components/PageSpecific/Search/AutoSearch';


const searchClient = algoliasearch(
    'YEBKU3WQGV', 
    '11b0caf54cf089a912d7f64f0e3e29fd');

const SearchQuery = () => {
    const navigate = useNavigate();

    // Function to return Algolia top hits
    const HitResult = ({hit, onClick}) => {
      return <Search hit={hit} onClick = {handleResultClick} />;
      };

    // Function to return Algolia top hits
    const ComposerResult = ({hit, onClick}) => {
      return <Composers hit={hit} onClick = {handleComposerClick} />;
      };




    // Function to handle search result click
    const handleResultClick = (hit) => {
      navigate(`/scores/${hit.objectID}`);
    };



    // Function to handle search result click
      const handleComposerClick = (hit) => {
        navigate(`/composer/${hit.composer_name}`);
      };
    

    // This will be refactored to navigate to filtered page
    const [selected, setSelected] = React.useState(1);
    const setSelectedItem = (value) => setSelected(value);


  // Custom wrapper component for handling no results
  function NoResultsBoundary({ children, fallback }) {
    const { results } = useInstantSearch();

    // The `__isArtificial` flag ensures not to display the No Results message
    // when no hits have been returned.
    if (!results.__isArtificial && results.nbHits === 0) {
      return (
        <>
          {fallback}
          <div hidden>{children}</div>
        </>
      );
    }

    return children;
  }

  // Custom component to display a "No Results" message
  function NoResults() {
    const { indexUiState } = useInstantSearch();

    return (
      <div className = 'flex flex-col mt-36 sm:mt-56 justify-center items-center text-lg'>
        <div className ='flex-col'>
        <p className = 'text-purple'>
          Womppp, looks like there are no results for <q>{indexUiState.query}</q>! Try browsing our curated recommendations and collections.
        </p>
        </div>
        <div className='flex-col'>
        <a href='/browse'>
        <h1 className = 'text-lg text-orange font-bold'>
        Browse here
        </h1>
        </a>
        </div>
      </div>
    );
  }


    // Conditional logic, default search screen until user queries in searchbar
    function EmptyQueryBoundary({ children, fallback }) {
      const { indexUiState } = useInstantSearch();
  
    // default screen - user has not typed in query
      if (!indexUiState.query) {
          return (
              <div className = 'px-4'>
              <div className="py-1 text-purple flex text-lg flex-col font-dico">
                <span className>
                Trending collections
                </span>
                <Card className="w-full shadow-none">
                <List>
                <div className="flex items-center">
                <BiTrendingUp className="text-2xl text-purple" />
                <ListItem
                className="text-purple font-dico border-b hover:bg-light-white"
                 selected={selected === 2}
                 onClick={() => setSelectedItem(1)}
                  >
                 Top 10 Classical Piano Songs
                 </ListItem>
                  </div>
  
                 <div className="flex items-center">
                 <BiTrendingUp className="text-2xl text-purple" />
                  <ListItem
                    className="text-purple font-dico border-b hover:bg-light-white"
                    selected={selected === 2}
                    onClick={() => setSelectedItem(2)}
                    >
                    Best of Beethoven
                   </ListItem>
                  </div>
  
                 <div className="flex items-center">
                  <BiTrendingUp className="text-2xl text-purple" />
                  <ListItem
                    className="text-purple font-dico border-b hover:bg-light-white"
                    selected={selected === 3}
                    onClick={() => setSelectedItem(3)}
                  >
                   Bach Cello Sonatas: C major
                   </ListItem>
                   </div>
                   </List>
                   </Card>
                  </div>
              </div>
          );
      }
      return children;
  }

  // alternate screen - user types query in search bar 
  return (
    <div>
    <div className = 'container mx-auto px-10 sm:px-16'>
    <InstantSearch searchClient={searchClient} indexName="scores" routing = {true}>
    <Configure hitsPerPage={10} />
        <div>
        <div className = 'fixed top-0 bg-white container mx-auto w-[80%] mb-2'> 

        {/*********  Algolia search bar **********/}
          <AlgoliaSearchbar />
          
          {/*********  Suggested pages - tag button **********/}
          <div className=' ml-1'>
          <AutoSearch />
          </div>

        </div>
        <div className='mt-36'>

        {/*********  default search view **********/}
        <EmptyQueryBoundary fallback={null}>
        <div className = ''>

        {/********* alternative search - dynamic search results **********/}  
        <NoResultsBoundary fallback={<NoResults />}>

      {/********* score hits **********/}  
        <div className = 'sm:grid sm:grid-cols-3'>
        <div className = 'sm:col-span-2'>
        <Index indexName="scores">
        <Hits
            hitComponent={HitResult}
            />
        </Index>
        </div>


        {/********* composers **********/}  
        <div className = 'sm:col-span-1'>
        <h1
        className = 'text-purple font-bold text-md mb-2 font-dico-code'>
          Composers
        </h1>
        <Index 
        indexName="composers">
        <Configure hitsPerPage={3} />
        <Hits
            hitComponent={ComposerResult}
            />
        </Index>
        </div>
        </div>

        </NoResultsBoundary>
        </div>
         </EmptyQueryBoundary>
         </div>
        </div>
    </InstantSearch>
    </div>

    {/******** mobile bottom nav bar  *******/}
    <div className = 'fixed bottom-0 md:hidden bg-white'>
    <MobileBottomNavbar />
    </div>
    </div>
  )
}

export default SearchQuery



