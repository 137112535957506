import React from 'react';
import Hero from '../components/PageSpecific/Home/Hero';
import ComposerRow from '../components/PageSpecific/Home/Composer';
import CTAButton from '../components/Common/Buttons/CTAButton';
import Navbar from '../components/Common/Navbar/Navbar';
import { period } from '../config/filterConfig';
import { instrument } from '../config/filterConfig';
import TopWorks from '../components/PageSpecific/Home/TopWorks'
import CategoryTag from '../components/Common/Tags/CategoryTag';
import Instrument from '../components/Common/Card/Instrument';
import { Link } from 'react-router-dom';


const Home = () => {
  return (
    <div
    className = 'bg-hero-pattern bg-no-repeat bg-[center_top] z-10'>

      {/*************** Nav bar ***************/}
      <Navbar textColor={'text-purple'} 
      logoPath={'logo-p-p.svg'} 
      SearchBorderColor = {'border-purple'}
      placeholderColor = {'placeholder-purple'}
      />
      
      <div className='mt-5 container mx-auto px-16'>
      {/************* Main header ************/}
      <Hero />

      {/*************** 1st header section - Value Prop ***************/}
      <div className="flex flex-wrap justify-center mb-4 mt-4">
        <h2 
        className="mb-2 text-3xl sm:text-4xl font-extrabold text-orange font-dico">
          Choose from a vast selection. </h2>
      </div>

      {/*************** 2nd section - Popular works carousel ***************/}
        <div className="mb-12">
          <TopWorks rowID ='1' />
        </div>
        </div>



      {/*************** 3rd header section - composer carousel ***************/}
      <div className="bg-background-orange">
      <div className='container mx-auto px-16 py-6'>

      <div className="flex flex-col md:flex-row">
        <div className= "flex flex-col justify-center max-w-xs sm:max-w-md mr-24 md:mr-10">
          <h2 className="mb-4 text-3xl sm:mb-8 sm:text-4xl text-orange font-dico font-extrabold">
          All the best composers in one place.
            </h2>
          <p className="mb-8 text-purple text-xl font-normal font-dico sm:text-2xl">
          From your favorite classical composers to curated playlists, it has never been easier to find the music you want to play.          </p>
          <div>
            <CTAButton text="Discover free music" link="/browse" />
          </div>
        </div>

        {/* Composer carousel */}
        <div className="flex overflow-hidden items-center md:hidden">
          <ComposerRow rowId = "2" />
        </div>

    <div
    className = 'hidden md:flex'>

    <div className='grid grid-cols-2 place-items-center'>
    <Link to='/composer/Wolfgang Amadeus Mozart'
    className='flex flex-col items-center hover:cursor-pointer transform hover:scale-105 transition-transform duration-300'
    >
      
    <img 
    className ='w-[220px] '
    src='/assets/composers/Mozart.png' alt='composer' />
    <div>
      <span
      className = 
      'font-xs text-purple leading-5 font-bold'>
        Wolfgang Amadeus Mozart
        </span>
      </div>
      </Link>

    <Link to='/composer/Johann Sebastian Bach'
    className='flex flex-col items-center hover:cursor-pointer transform hover:scale-105 transition-transform duration-300'
    >
    <img 
    className = 'w-[300px]'
    src='/assets/composers/Bach.png' alt='composer' />
    <div>
      <span
      className = 
      'font-xs text-purple leading-5 font-bold'>
        Johann Sebastian Bach</span>
    </div>
    </Link>

    <Link to='/composer/Ludwig van Beethoven' 
    className='flex flex-col items-center hover:cursor-pointer transform hover:scale-105 transition-transform duration-300'
    >
    <img
    className = 'w-[300px]' 
    src='/assets/composers/Beethoven.png' alt='composer' />
    <div>
      <span
      className = 
      'font-xs text-purple leading-5 font-bold'>
      Ludwig van Beethoven</span>
    </div>
  </Link>

    <Link to='/composer/Frédéric Chopin'
    className='flex flex-col items-center hover:cursor-pointer transform hover:scale-105 transition-transform duration-300'>
    <img
    className = 'w-[220px]' 
    src='/assets/composers/Chopin.png' alt='composer' />
    <div>
      <span
      className = 
      'font-xs text-purple leading-5 font-bold'>
        Frédéric Chopin</span>
     </div>
    </Link>
    </div>
    </div>

      </div>
      </div>
      </div >

      {/*************** 4th header section - browse by instrument ***************/}
      <div className='container mx-auto px-16'>
      <div
      className = 'flex justify-center text-center mt-16'>
        <h2
        className = 'text-3xl lg:text-4xl font-extrabold font-dico text-orange'>
          What instruments do you like to play?
        </h2>
      </div>
      
      <div
      className = 'flex justify-center mt-6 flex-wrap'>

        {instrument.map((filter, index) => (
        <Instrument
          key={index}
          facetName={filter.facetName}
          subcategory={filter.subcategory}
          filterCategory={{ instrument }}
        />
      ))}
        
      </div>
      </div>

      {/* orange background */}
      <div className="bg-background-orange">

      {/*************** 5th header section - browse by period ***************/}
        <div className ='container mx-auto px-16'>

        <div className="flex flex-col justify-center items-center mb-10 mt-10">
          <div className = "text-center lg:w-[572px]" >
          <h2 className="mt-10 mb-6 text-3xl lg:text-4xl font-extrabold font-dico text-orange">
            What do you like to play?
            </h2>
          <p className="mb-6 text-purple text-xl sm:text-2xl	font-normal font-dico">
            With more than 2,000 digital works, our library is one of the largest in the world.
          </p>
          </div>
          <div className='flex flex-wrap lg:w-[1000px] justify-center'>

      {period.map((filter, index) => (
        <CategoryTag
          key={index}
          facetName={filter.facetName}
          subcategory={filter.subcategory}
          backgroundColor={'bg-white'}
          filterCategory={{ period }}
        />
      ))}
          </div>
          </div>

      </div>
      
      {/*************** Bottom nav bar ***************/}
      <Navbar 
      backgroundColor={'bg-purple'} 
      textColor={'text-off-white'} 
      logoPath={'logo-o-ow.svg'} 
      SearchBorderColor= {'border-off-white'}
      placeholderColor= {'placeholder-off-white'}
       />
    </div>
    </div>
  );
}

export default Home;