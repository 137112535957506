import React from 'react'
import { Highlight } from 'react-instantsearch';
import getIconByInstrumentType from '../../../metadata/iconIdentifier';
import { FaChevronRight } from "react-icons/fa6";


{/****** Return Algolia search result hits ***********/}
{/****** CSS display: returns as a vertical list ***********/}

const getRandomCoverArt = () => {
  const randomNumber = Math.floor(Math.random() * 15) + 1; 
  return `../assets/scoreart/Placeholder-${randomNumber}.png`;
};

const Search = ( {hit, onClick} ) => {
  return (
    <div className = 'w-[99%]	cursor-pointer bg rounded-md bg-white hover:bg-light-white mb-2'>
    <button
    className ='w-full flex items-center' 
    onClick = {() => onClick(hit)}>

    <div className = 'flex items-center'>
      <img className='w-[100px] ml-2 h-auto py-1' 
      src={hit.cover_art || getRandomCoverArt()} 
      alt={`Popular work`} 
      loading="lazy" />
      <div className = 'flex-col p-3'>

      <div className='flex text-left'>
        <p className='leading-7 text-base font-dico font-bold text-purple'>
          <Highlight attribute = 'title' hit = {hit} />
          </p>
      </div>

      <div className='flex mb-1 text-left'>
        <p className='font-dico-code font-bold text-sm text-purple text-transform: uppercase'>
          <Highlight attribute = 'composer_name' hit = {hit} />
          </p>
      </div>

      <div className='flex'>
      {getIconByInstrumentType(hit.instrument, 'w-3.5', 'text-sm')}
      </div>
        </div>
        </div>

        <div className = 'flex ml-auto text-xl text-purple p-4'>
        <FaChevronRight />
        </div>

        </button>
        </div>
  )
}

export default Search;




