// Instrument icons to add as score descriptors

const strings =
['Strings', 'strings', 'Orchestra', 'String Ensemble', 'String Quartet', 'Ensemble', 'Quartet']

const piano = 
['Piano', 'Organ', 'Harpsichord', 'Clavichord', 'Piano Duet', 'Clavier', 'piano']

const voice =
['Voice', 'voice', 'Choir', 'choir', 'SATB', 'Voice (SATB)', 'Choir (SATB)', 'Voice (SATTB)', 'SSATB choir']

const guitar =
['Guitar', 'guitar']

const horn = 
['horns', 'horn', 'Horns', 'horns']

const cello =
['Cello', 'cello']

const violin =
['Violin', 'violin', 'viola', 'Viola']

const bass =
['Bass', 'bass', 'Basso Continuo', 'Double Bass']

const harpsichord =
['harpsichord', 'Harpsichord', 'Clavichord', 'clavichord']

const brass = 
['trumpet','Trumpet', 'French horn', 'French horns', 'french horn', 'trombone','Trombone']




const StringsIcon = ({ img_width, font_size }) => 
<div className = 'flex'>
<img 
src="/assets/icons/Instrument/String-icon-orange.svg" 
alt="Strings Icon"
className = {`${img_width} mr-1`} />
<span
className={`text-purple text-dico-code font-bold text-transform: uppercase ${font_size}`}>
    Strings
</span>
</div>


const PianoIcon = ({ img_width, font_size }) => 
<div className='flex'>
<img 
src="/assets/icons/Instrument/Piano-icon-orange.svg" 
alt="Piano Icon"
className = {`${img_width } mr-1`} />
<span
className={`text-purple text-dico-code font-bold text-transform: uppercase ${font_size}`}>
    Piano
</span>
</div>;

const VoiceIcon = ({ img_width, font_size }) => 
<div className='flex'>
<img 
src="/assets/icons/Instrument/Voice-icon.svg" 
alt="Voice Icon"
className = {`${img_width } mr-1`} />
<span
className={`text-purple text-dico-code font-bold text-transform: uppercase ${font_size}`}>
    Voice
</span>
</div>;


const GuitarIcon = ({ img_width, font_size }) => 
<div className='flex'>
<img 
src="/assets/icons/Instrument/Guitar-icon.svg" 
alt="Guitar Icon"
className = {`${img_width} mr-1`} />
<span
className={`text-purple text-dico-code font-bold text-transform: uppercase ${font_size}`}>
    Guitar
</span>
</div>;


const HornIcon = ({ img_width, font_size }) => 
<div className='flex'>
<img 
src="/assets/icons/Instrument/Horns-icon.svg" 
alt="Horn Icon"
className = {`${img_width } mr-1`} />
<span
className={`text-purple text-dico-code font-bold text-transform: uppercase ${font_size}`}>
    Horn
</span>
</div>;

const CelloIcon = ({ img_width, font_size }) => 
<div className='flex'>
<img 
src="/assets/icons/Instrument/Cello-icon.svg" 
alt="Cello Icon"
className = {`${img_width } mr-1`} />
<span
className={`text-purple text-dico-code font-bold text-transform: uppercase ${font_size}`}>
    Cello
</span>
</div>;

const ViolinIcon = ({ img_width, font_size }) => 
<div className='flex'>
<img 
src="/assets/icons/Instrument/Violin-icon.svg" 
alt="Violin Icon"
className = {`${img_width } mr-1`} />
<span
className={`text-purple text-dico-code font-bold text-transform: uppercase ${font_size}`}>
    Violin
</span>
</div>;

const BassIcon = ({ img_width, font_size }) => 
<div className='flex'>
<img 
src="/assets/icons/Instrument/Bass-icon.svg" 
alt="Bass Icon"
className = {`${img_width } mr-1`} />
<span
className={`text-purple text-dico-code font-bold text-transform: uppercase ${font_size}`}>
    Bass
</span>
</div>;

const HarpsichordIcon = ({ img_width, font_size }) => 
<div className='flex'>
<img 
src="/assets/icons/Instrument/Harpsichord-icon.svg" 
alt="Harpsichord Icon"
className = {`${img_width } mr-1`} />
<span
className={`text-purple text-dico-code font-bold text-transform: uppercase ${font_size}`}>
    Harpsichord
</span>
</div>;


const BrassIcon = ({ img_width, font_size }) => 
<div className='flex'>
<img 
src="/assets/icons/Instrument/Brass-icon.svg" 
alt="Brass Icon"
className = {`${img_width } mr-1`} />
<span
className={`text-purple text-dico-code font-bold text-transform: uppercase ${font_size}`}>
    Brass
</span>
</div>;


// Function to get instrument icon based on instrument type or types
const getIconByInstrumentType = (instrumentTypes, img_width, font_size) => {
    if (!instrumentTypes) {
      return null;
    }

    // Convert to array if it's a string
    const instrumentArray = Array.isArray(instrumentTypes)
      ? instrumentTypes
      : [instrumentTypes];


    // Check if any instrument type is in the predefined arrays
    if (instrumentArray.some(type => strings.includes(type))) {
      return <StringsIcon img_width={img_width} font_size={font_size} />
    }

    if (instrumentArray.some(type => piano.includes(type))) {
      return <PianoIcon img_width={img_width} font_size={font_size} />;
    }

    if (instrumentArray.some(type => voice.includes(type))) {
      return <VoiceIcon img_width={img_width} font_size={font_size} />;
    }

    if (instrumentArray.some(type => guitar.includes(type))) {
      return <GuitarIcon img_width={img_width} font_size={font_size} />;
    }

    if (instrumentArray.some(type => horn.includes(type))) {
      return <HornIcon img_width={img_width} font_size={font_size} />;
    }

    if (instrumentArray.some(type => cello.includes(type))) {
      return <CelloIcon img_width={img_width} font_size={font_size} />;
    }

    if (instrumentArray.some(type => violin.includes(type))) {
      return <ViolinIcon img_width={img_width} font_size={font_size} />;
    }

    if (instrumentArray.some(type => bass.includes(type))) {
      return <BassIcon img_width={img_width} font_size={font_size} />;
    }

    if (instrumentArray.some(type => harpsichord.includes(type))) {
      return <HarpsichordIcon img_width={img_width} font_size={font_size} />;
    }

    if (instrumentArray.some(type => brass.includes(type))) {
      return <BrassIcon img_width={img_width} font_size={font_size} />;
    }

    return <div className='mt-5'></div>;
};

export default getIconByInstrumentType;


  
  
  
  
  
  


