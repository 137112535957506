import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import LinearProgress from '@mui/material/LinearProgress';
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Search from "./pages/Search";


// Lazy-loaded components
const Account = lazy(() => import("./pages/Account"));
const Browse = lazy(() => import("./pages/Browse"));
const BrowseCategory = lazy(() => import("./pages/BrowseCategory"));
const MusicPDFViewer = lazy(() => import('./pages/MusicPDFViewer'));
const Score = lazy(() => import("./pages/ScorePage"));
const Composer = lazy(() => import("./pages/Composer"));
const JoinTheWaitlist = lazy(() => import("./pages/JoinTheWaitlist"));


function App() {
  return (
    <>
      <AuthContextProvider>
        <Suspense fallback={<LinearProgress />}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/account' element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            } />
            <Route path='/browse' element={<Browse />} />
            <Route path="/browse/:filter" element={<BrowseCategory />} />
            <Route path='/search' element={<Search />} />
            <Route path='/musicreader/:score_id' element={<MusicPDFViewer />} />
            <Route path="/scores/:objectID" element={<Score />} />
            <Route path='/composer/:composer_name' element={<Composer />} />
            <Route path='/waitlist' element={<JoinTheWaitlist />} />
          </Routes>
        </Suspense>
      </AuthContextProvider>
    </>
  );
}

export default App;

