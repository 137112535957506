import React from 'react';
import { MdChevronLeft, MdChevronRight} from 'react-icons/md';


const Row = ({ rowID, rowContent, rowTitle }) => {

    {/*************** Left slider *********************/}
    const slideLeft = () => {
      const slider = document.getElementById('slider' + rowID);
      const scrollAmount = slider.clientWidth / 2; // Scroll half of the container's width
      slider.scrollLeft -= scrollAmount;
    }
    
    {/*************** Right slider *********************/}
    const slideRight = () => {
      const slider = document.getElementById('slider' + rowID);
      const scrollAmount = slider.clientWidth / 2; // Scroll half of the container's width
      slider.scrollLeft += scrollAmount;
    }
    
  return (
      <div className='relative items-center group'> 
      <MdChevronLeft
      onClick={slideLeft}
          className='bg-white left-0 top-1/2 rounded-full absolute opacity-50 hover:opacity-100 cursor-pointer z-10 hidden group-hover:block'
          size={40}
        />  

        {/*************** Row Title *********************/}
        <div className = 'flex flex-col'>
        <div className='flex justify-between text-purple items-center'>
        <div className='text-2xl sm:text-3xl font-dico font-normal mb-4'>
          <p>{rowTitle}</p>
          </div>


        {/********* Browse all [WIP, decide if this will be included]  **********/}
        {/* <div
        onClick = {() => handleSeeMoreClick(filterCondition)}>
        <div
        className='hidden sm:flex items-center sticky'>
        <span className ='font-dico text-base font-bold'>
            Browse all works
          </span>
            <span className='ml-2 text-orange'>
            <AiOutlineArrowRight />
            </span>
        </div>
        </div> */}

        </div>

        {/*************** Row Content variable *********************/}
        <div className = 'flex'>
        <div id={'slider' + rowID} className='w-screen h-full overflow-x-scroll whitespace-nowrap scroll-smooth scrollbar-hide relative'>  
        {rowContent}
         </div>
         </div>
         </div> 

        <MdChevronRight
        onClick={slideRight}
          className='bg-white right-0 top-1/2 rounded-full absolute opacity-50 hover:opacity-100 cursor-pointer z-10 hidden group-hover:block'
          size={40}
        />
        </div>
  )
}

export default Row