import React from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { applyFilterAndRedirect } from '../../../config/algoliaConfig';
import { useNavigate } from 'react-router-dom';


const CategoryTag = ({ facetName, subcategory, backgroundColor, cssDisplay, filterCategory }) => {

  const navigate = useNavigate();
 
  const handleSubcategoryClick = (facet, value) => {
    const filterArray = filterCategory[facet];
    if (filterArray) {
      const filter = filterArray.find((item) => item.subcategory === value);
      if (filter) {
        applyFilterAndRedirect(filter.facetName, filter.subcategory, navigate);
      }
    }
  };

  return (
  <div className = {`${cssDisplay}`}>
        <button
          onClick={() => handleSubcategoryClick(facetName, subcategory)}
          className={`${backgroundColor} hover:bg-gray-200 font-dico font-bold py-2 px-4 rounded-full mr-4 mb-4 text-purple inline-flex items-center whitespace-nowrap`}
        >
        <span className="mr-2">
        <AiOutlineCheck />
        </span>
          {subcategory}
        </button>
    </div>
  );
};

export default CategoryTag;



