import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';

const CTAButton = ({ text, link }) => {
  return (
    <div>
      <a href={link}>
      <button 
      className='bg-orange hover:bg-hov-orange transition duration-100 ease-in-out text-white w-[200px] h-[50px] rounded flex items-center justify-center'>
        <span 
        className="font-dico font-medium mr-2 text-[17px]"> 
        {text} 
        </span>
        <AiOutlineArrowRight />
      </button>
      </a>
    </div>
  );
};

export default CTAButton;