// description for period category page 
//temporary ... data should be uploaded to firestore

const instruments = {
    Piano: {
      description: "A grandiose and ornate musical style from the 17th and early 18th centuries, characterized by expressive melodies, contrast, and elaborate ornamentation.",
      composers: ["Johann Sebastian Bach", "George Frideric Handel", "Antonio Vivaldi"],
      tags: ["Contrast", "Ornate", "Expressive", "Harpsichord", "Polyphony"],
      src: "/assets/icons/Instrument/Piano-icon-orange.svg"
    },
    Violin: {
      description: "An elegant and balanced musical style from the late 18th and early 19th centuries, marked by clarity, formality, and adherence to tonal structures.",
      composers: ["Wolfgang Amadeus Mozart", "Ludwig van Beethoven", "Joseph Haydn"],
      tags: ["Balanced", "Formal", "Clear", "Symmetry", "Melodic"],
      src: "/assets/icons/Instrument/Violin-icon.svg"
    },
    Cello: {
      description: "A passionate and emotive musical movement from the 19th century, emphasizing individual expression, dramatic dynamics, and expanded harmonic language.",
      composers: ["Ludwig van Beethoven", "Johannes Brahms", "Pyotr Ilyich Tchaikovsky"],
      tags: ["Passionate", "Emotive", "Dramatic", "Expressive Harmonies", "Individualism"],
      src: "/assets/icons/Instrument/Cello-icon.svg"
    },
    Orchestra: {
      description: "An early phase of the Romantic era, featuring lyricism, exploration of nationalistic themes, and a departure from Classical norms.",
      composers: ["Franz Schubert", "Hector Berlioz", "Carl Maria von Weber"],
      tags: ["Lyrical", "Nationalistic", "Expressive", "Programmatic", "Poetic"],
      src: "/assets/icons/Instrument/Score-icon.svg"
    },
    Guitar: {
      description: "Music rooted in the traditions of a particular region or community, often passed down orally, and characterized by simplicity, authenticity, and cultural influences.",
      composers: ["Béla Bartók", "Ralph Vaughan Williams", "Zoltán Kodály"],
      tags: ["Authentic", "Cultural", "Traditional", "Melodic", "Rhythmic"],
      src: "/assets/icons/Instrument/Guitar-icon.svg"
    },
    Horn: {
      description: "The latter part of the Romantic era, known for lush orchestration, intense emotional expression, and a fascination with exoticism and supernatural themes.",
      composers: ["Richard Strauss", "Gustav Mahler", "Claude Debussy"],
      tags: ["Lush Orchestration", "Intense Emotion", "Exoticism", "Supernatural", "Colorful Harmony"],
      src: "/assets/icons/Instrument/Horn-icon.svg"
    },
    Voice: {
      description: "A period from the 14th to 17th centuries, marked by polyphony, modal harmonies, and a revival of interest in ancient Greek and Roman art and literature.",
      composers: ["Josquin des Prez", "Giovanni Palestrina", "Thomas Morley"],
      tags: ["Polyphony", "Modal Harmonies", "Imitative", "A Capella", "Humanism"],
      src: "/assets/icons/Instrument/Voice-icon.svg"
    },
    Bass: {
      description: "A movement focused on technical aspects of music, often associated with virtuosic performance and innovations in instrumental and vocal capabilities.",
      composers: ["Franz Liszt", "Nicolò Paganini", "Frédéric Chopin"],
      tags: ["Virtuosic", "Innovative", "Expressive", "Challenging", "Dazzling"],
      src: "/assets/icons/Instrument/Bass-icon.svg"
    },
  };
  
  export default instruments;