import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { UserAuth } from '../../../context/AuthContext';
import NavSearchbar from './NavSearchbar';
import { BsFillPersonFill, BsMusicNote } from 'react-icons/bs';
import AuthPopupForm from '../../AuthPopup/Form';
import { MdOutlineLibraryMusic } from "react-icons/md";
import { IoMdExit } from "react-icons/io";




const Navbar = ({ backgroundColor, textColor, logoPath, SearchBorderColor, placeholderColor }) => {

  const {user, logOut} = UserAuth();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);


  const browse = async () => {
    try {
      navigate('/browse');
    }
    catch(error) {
      console.log(error)
    }
  }


  const handleLogout = async () => {
    try {
      await logOut();
      navigate('/');
    }
    catch(error){
      console.log(error)
    }
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <div className = {`${backgroundColor}`}>

    
    {/*********** Logo img ************************/}
    <div className= 'flex items-center justify-between mx-auto px-12 py-5 max-w-[1240px]'>
      <Link to='/'>
        <img 
        className='w-[115px]'
        src={`${logoPath}`} alt='Legatto logo'></img>
      </Link>

      {/*********** Searchbar component ************************/}
      <NavSearchbar 
      borderColor= {`${SearchBorderColor}`}  
      placeholderColor={`${placeholderColor}`} 
      textColor= {`${textColor}`} 
      />

      {/*********** Browse button ************************/}
      <div 
      onClick={browse} 
      className = 'cursor-pointer'>
          <div className='flex items-center'>
            <div className='text-orange mr-1 font-extrabold'>
              <BsMusicNote />
            </div>
            <h2 className={`${textColor} pr-4 font-dico text-base font-bold`}>Browse</h2>
          </div>
        </div>

      {/*********** Log in / Log out / Account button ************************/}
      <div className ='hidden sm:flex'>
      {user?.email ? (   
        <div className='flex items-center'>
        <Link to='/'>
          <button className={`${textColor} pr-4 font-dico text-base font-bold flex items-center`}>
          <MdOutlineLibraryMusic
          className = 'text-orange mr-1' />
            My library</button>
        </Link>


        <button 
        onClick={handleLogout} className='flex bg-orange px-6 py-2 rounded cursor-pointer items-center'>
          <IoMdExit
          className = 'text-purple' />
          <span
          className = 'ml-1'>
          Logout
          </span>
        </button>
        </div>)
:
          (
            <div className = 'flex items-center cursor-pointer'>
            <div className='text-orange mr-2'>
            <BsFillPersonFill />
            </div>
            <button
            onClick={openModal}
            className={`${textColor} pr-4 font-dico text-base font-bold`}>Log in</button>
            </div>
          
          )
      }
        </div>
        </div>

        {isModalOpen && (
        <div className='fixed top-0 left-0 z-10 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center'>
            <AuthPopupForm closeModal = {closeModal} />
        </div>
      )}

        </div>
  )
}

export default Navbar