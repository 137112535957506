import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai'; 
import { Link } from 'react-router-dom';

const NavSearchbar = ({ borderColor, placeholderColor, textColor }) => {

  return (
    <Link to ="/search" >
    <form 
    className="flex items-center space-x-2"
    >
      <div className="relative flex items-center">
      <div>
      <AiOutlineSearch 
      className="text-orange sm:hidden text-xl ml-8" />
      </div>
      <div className="hidden sm:flex">

      {/************* Search icon ************************/}
        <div className="absolute inset-y-0 pl-3 flex items-center pointer-events-none">
          <AiOutlineSearch className="text-orange" />
        </div>

      {/************* Search bar ************************/}
        <input
          type="text"
          placeholder="Search"
          name="search"
          className={`px-2 py-1 lg:w-[34rem] rounded-full 
          focus:outline-none focus:ring-1 focus:${textColor}
          border ${borderColor} bg-transparent ${placeholderColor} 
          ${textColor}` }
          style={{ paddingLeft: '35px' }} 
        />
        </div>
      </div>
    </form>
    </Link>
  );
};

export default NavSearchbar;


