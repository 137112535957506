import { getDocs, collection, query, limit, getFirestore } from 'firebase/firestore/lite';
import { app } from '../services/firebase.js';
import { where } from 'firebase/firestore';

const firestoreApp = app;
const db = getFirestore(firestoreApp);

// Fetch initial hits (ex: carousels)
export const fetchInitialResults = async (collectionName, conditions, limitCount) => {
  const dataCollection = collection(db, collectionName);
  const q = query(dataCollection, ...conditions, limit(limitCount));
  const querySnapshot = await getDocs(q);

  const formattedData = [];
  querySnapshot.docs.forEach((doc) => {
    const { title, composer_name, instrument, cover_art } = doc.data();
    const objectID = doc.id;

    formattedData.push({
      title,
      composer_name,
      instrument,
      cover_art,
      objectID
    });
  });

  return formattedData;
};


// Fetch all results from Firestore
export const fetchAllResults = async (collectionName, conditions) => {
  const dataCollection = collection(db, collectionName);
  const q = query(dataCollection, ...conditions);
  const querySnapshot = await getDocs(q);

  const formattedData = [];
  querySnapshot.docs.forEach((doc) => {
    const { title, composer_name, instrument, cover_art } = doc.data();
    const objectID = doc.id;

    formattedData.push({
      title,
      composer_name,
      instrument,
      cover_art,
      objectID
    });
  });

  return formattedData;
};


// Fetch similar pieces by composer
export const fetchComposerRecs = async (collectionName, conditions, limitCount) => {
  const dataCollection = collection(db, collectionName);
  const q = query(dataCollection, ...conditions, limit(limitCount));
  const querySnapshot = await getDocs(q);

  const formattedData = [];
  querySnapshot.docs.forEach((doc) => {
    const { title, composer_name, instrument, cover_art } = doc.data();
    const objectID = doc.id;

    formattedData.push({
      title,
      composer_name,
      instrument,
      objectID,
      cover_art
    });
  });

  return formattedData;
};


// Fetch composer images
export const fetchComposerImage = async (composerName) => {
  const composerRef = collection(db, 'composers');
  const q = query(composerRef, where('composer_name', '==', composerName));
  const querySnapshot = await getDocs(q);

  const formattedData = [];
  querySnapshot.docs.forEach((doc) => {
    const { composer_name, tile_img } = doc.data();

    formattedData.push({
      composer_name,
      tile_img
    });
  });

  return formattedData;
};