import React from 'react';
import CarouselData from '../../Common/Carousel/CarouselData'; 
import { where } from 'firebase/firestore/lite';


const TopWorks = ({ rowID }) => {
  // Define your filter condition
  const filter = 'isTop50'
  const filterCondition = [where(filter, '==', true)]

  return (
    <CarouselData
    rowID = {rowID}
    filterCondition = {filterCondition} />
  );
};

export default TopWorks;