import React from 'react'
import { FaChevronRight } from 'react-icons/fa6';
import { Highlight } from 'react-instantsearch';


{/****** Return Algolia search result hits ***********/}
{/****** CSS display: returns as a vertical list ***********/}

const Composers = ( {hit, onClick} ) => {
  return (
    <div className = 'w-[99%]	cursor-pointer bg rounded-md bg-white hover:bg-light-white mb-2'>
    <button
    className ='w-full flex items-center' 
    onClick = {() => onClick(hit)}>

    <div className = 'flex items-center'>
      <img className='w-[100px] ml-2 h-auto py-1' 
      src={hit.tile_img || 'assets/composers/Default.png'}  
      alt={hit.composer_name} 
      loading="lazy" />
      <div className = 'flex-col p-3'>
      <div className='flex text-left'>
        <p className='leading-7 font-dico-code font-bold text-base text-purple text-transform: uppercase'>
          <Highlight attribute = 'composer_name' hit = {hit} />
          </p>
      </div>
        </div>
        </div>

        <div className = 'flex ml-auto text-xl text-purple p-4'>
        <FaChevronRight />
        </div>

        </button>
        </div>
  )
}

export default Composers;