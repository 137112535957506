import React from 'react';
import { period } from '../../../config/filterConfig';
import CategoryTag from '../../Common/Tags/CategoryTag';


// 

const AutoSearch = () => {
  return (
    <div className='flex flex-nowrap overflow-x-auto'>

    {period.map((filter, index) => (
      <CategoryTag
        key={index}
        facetName={filter.facetName}
        subcategory={filter.subcategory}
        backgroundColor={'bg-off-white'}
        filterCategory={{ period }}
      />
    ))}
        </div>
  )
}

export default AutoSearch