import React from 'react'
import { useNavigate } from 'react-router-dom';
import { applyFilterAndRedirect } from '../../../config/algoliaConfig';
import instruments from '../../../metadata/instrument';

const Instrument = ({ facetName, subcategory, filterCategory }) => {


  const navigate = useNavigate();

  // algolia redirect to instrument page
  const handleSubcategoryClick = (facet, value) => {
    const filterArray = filterCategory[facet];
    if (filterArray) {
      const filter = filterArray.find((item) => item.subcategory === value);
      if (filter) {
        applyFilterAndRedirect(filter.facetName, filter.subcategory, navigate);
      }
    }
  };


// dynamically populate icon from subcategory
  const instrumentData = instruments[subcategory];
  if (!instrumentData) {
    return null;
  }



  return (
    <button
    onClick={() => handleSubcategoryClick(facetName, subcategory)}
    className = 'bg-off-white w-[110px] sm:w-[120px] rounded-lg border border-hov-off-white mb-4 md:mr-4 mr-2 hover:bg-hov-off-white transition duration-100 p-2 active:bg-click-off-white'>        
    <div className = 'flex flex-col p-4 items-center justify-center'>
        <img
        className = 'flex w-[65%] p-1'
        src = {instrumentData.src}
        alt='instrument'>
        </img>
        <span
        className = 'leading-7 text-purple font-dico text-large font-bold'>
            {subcategory}
        </span>

        </div>
    </button>
  )
}

export default Instrument