import React, { useEffect, useState } from 'react';
import Row from '../../Common/Carousel/Row';
import { fetchInitialResults } from '../../../config/firestoreQuery.js'
import Carousel from '../../Hits/Carousel/Carousel.jsx';
import { useMemo } from 'react';

const CarouselData = ({ rowID, filterCondition, rowTitle}) => {
  const [data, setData] = useState([]);


{/********* format condition for firestore query **********/}
  const condition = useMemo(() => filterCondition);


{/********* call firestore query **********/}
  useEffect(() => {
    const fetchData = async () => {
      const queryData = await fetchInitialResults('scores', condition, 8);
      setData(queryData);
    };

    fetchData();
  }, [condition]);


{/*********  format hits data **********/}
const DataFeed = () => {
  return (
    <Carousel data={data} />
  )
}

{/*********  return hits data into carousel row **********/}
  return (
    <div>
        <Row 
        rowID={rowID} 
        rowContent={<DataFeed />} 
        rowTitle={rowTitle} 
        filterCondition = {condition}
         />
    </div>
  );
};
export default CarouselData;



