import React from 'react';
import Row from '../../Common/Carousel/Row'
import { Link } from 'react-router-dom';


const Composer = () => {

  // Array of composer data for row content  
  const ComposerData = [
    {
      imagePath: 'assets/composers/Bach.png',
      composer: 'Johannes Sebastian Bach',
      url: '/composer/Johann Sebastian Bach',
    },
    {
      imagePath: 'assets/composers/Beethoven.png',
      composer: 'Ludwig Van Beethoven',
      url:'/composer/Ludwig van Beethoven'
    },
    {
      imagePath: 'assets/composers/Chopin.png',
      composer: 'Frédéric Chopin',
      url:'/composer/Frédéric Chopin',
    },
    {
      imagePath: 'assets/composers/Mozart.png',
      composer: 'Wolfgang Amadeus Mozart',
      url:'/composer/Wolfgang Amadeus Mozart',
    },
    {
      imagePath: 'assets/composers/Tchaikovsky.png',
      composer: 'Pyotr Ilyich Tchaikovsky',
      url:'/composer/Pyotr Ilyich Tchaikovsky',
    },
  ]

  {/*********** Composer Layout **************/}
  return (
    <div>
      {ComposerData.map((item, index) => (
        <Link to ={item.url}
          key={index}
          className='w-[180px] sm:w-[220px] inline-block relative mr-10 hover:bg-hov-off-white transition duration-100 rounded-lg active:bg-click-off-white'>
          <img className='w-full h-auto' src={item.imagePath} alt={`Composer ${index}`} loading="lazy" />
          <div className = 'font-dico font-bold text-lg text-center text-purple whitespace-normal	'>
            <p
            className ='flex flex-wrap'
            >{item.composer}</p>
          </div>
        </Link>
      ))}
    </div>
  );
};

{/*********** Create Composer Row **************/}
const ComposerRow = ({ rowID }) =>{
  return (
  <div className = ''>
   <Row rowID={rowID} rowContent={<Composer />} rowBrowse={'Browse all composers'}/>
  </div>
  );
};

export default ComposerRow;